/* src/global.css */

body {
    margin: 0;
    padding: 0;
    font-family: 'Times New Roman', Times, serif;
    /* Set your preferred font */
    background-color: #363434;
    /* Set your preferred background color */
    color: #ece3e3;
    /* Set your preferred text color */
}

.page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 100vh;
    box-sizing: border-box;
}

.header {
    font-size: 2rem;
    font: bold;
    color: #ffffff;
    z-index: 1;
}

.back-button-container {
    position: relative;
    left: auto;
    bottom: auto;
    box-sizing: border-box;
    margin: 1rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 0rem;
}

.back-button {
    padding: 5px 10px;
    background-color: #242222;
    border: 1px solid #ccc;
    cursor: pointer;
    margin: 1rem;
    position: relative;
    bottom: auto;
    right: auto;
}

.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #04af0d;
    /* Blue */
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
    margin: 20px auto;
}

.content {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    /* Adjust width as per your design */
    max-width: 600vw;
    max-height: 250px;
    /* Set a max-width for larger screens */
    padding: 1rem;
    box-shadow: 0 0 10px rgba(223, 8, 8, 0.1);
    /* Optional: adds a subtle shadow */
    background-color: #131313;
    /* Background for content area */
    border-radius: 8px;
    /* Optional: rounds the corners */
    position: relative;
    z-index: 1;
    overflow: auto;
    box-sizing: border-box;
    margin-bottom: 1rem;
}

.inner-container {
    width: 100%;
    max-width: 720px;  /* Maximum width of the container */
    margin: auto;      /* Center the container */
    padding-top: 1rem; /* Top padding */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.checkout-summary{
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
    margin: 2rem auto;
    box-sizing: border-box;
    padding-top: 40px;
}

.checkout-summary h2,
.checkout-summary p {
    margin: 5px 0; /* Reduce the margin between elements */
}

.logo-container {
    position: absolute;
    top: 0; /* Position at the top of the page */
    width: 100%; /* Ensure the logo is centered horizontally */
    display: flex;
    justify-content: center; /* Center the logo horizontally */
    padding: 5vw 0; /* Adjust as needed for padding around the logo */
    box-sizing: border-box;
    height: auto;
    margin: 1vw auto;
    max-width: 200rem;
    z-index: 0;
}

.logo-container img {
    max-width: 300px;  /* Maximum size of the logo */
    width: 30vw;       /* Responsive width, scales with the viewport */
    height: auto;      /* Maintain aspect ratio */
    margin: 0rem auto; /* Vertical margin, centered horizontally */
}


input[type='checkbox'] {
    background-color: #077c0d;
}

img {
    width: 15%;
    height: auto;
    margin: 0 auto;
    display: block;
    fill: #04af0d;
    stroke: #04af0d;
}

button {
    background-color: #077c0d;
    /* Set your preferred highlight color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.next-button-container {
    position: relative;
    left: auto;
    bottom: auto;
    box-sizing: border-box;
    margin: 1rem auto;
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
    padding: 0 0rem;
}

.next-button {
    position: relative;
    bottom: auto;
    right: auto;
    margin: 1rem;
}

button:hover {
    background-color: #7c7c7c;
}

input,
select {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: calc(100% - 22px);
    /* Adjust input width */
}

.tabNavigation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-inline: auto;
    gap: 10px;
    cursor: pointer;
    z-index: 1;
}

.tabContent {
    display: none;
    width: 100%;
    margin: 0 auto;
    /* Adjust width as per your design */
    max-width: 600px;
    /* Set a max-width for larger screens */
    padding: 20px;
    box-shadow: 0 0 10px rgba(67, 65, 65, 0.1);
    /* Optional: adds a subtle shadow */
    background-color: #131313;
    /* Background for content area */
    border-radius: 8px;
    /* Optional: rounds the corners */
    position: relative;
    z-index: 1;
    box-sizing: border-box;
}

@media (min-width: 768px) {
    .inner-container {
        max-width: 720px; /* Fixed max width for larger screens */
        margin: auto; /* Center the container */
        padding: 2rem; /* More padding on larger screens */
    }

    .logo {
        width: 50vw; /* Larger proportion of the viewport */
        max-width: 500px; /* larger max size */
    }

    .content-box {
        padding: 2rem;
    }

    .button {
        padding: 1rem 2rem; /* Larger buttons on larger screens */
        font-size: 1.25rem; /* Larger font size on larger screens */
    }
}

/* Specific rules for mobile devices */
@media (max-width: 480px) {
    .logo {
        width: 50vw; /* Larger relative width on smaller screens */
        margin-top: 1rem; /* Reduced space above the logo */
    }

    .inner-container {
        padding: 1rem; /* Less padding so content has more space */
    }

    .content-box {
        padding: 0.5rem; /* Less padding inside the content box */
        margin-bottom: 0.5rem; /* Less space below the content box */
    }

    /* Adjust font sizes for smaller screens */
    h1, p {
        font-size: 4vw; /* Example of responsive font size based on viewport width */
    }

    /* Adjust button sizes for smaller screens */
    .button {
        padding: 0.5rem 1rem;
        font-size: 3.5vw;
    }
}